<template>
  <v-card>
    <h2 style="text-align: center">Files Management</h2>
    <!-- <v-toolbar flat color="#0C2759" dark>
      <v-toolbar-title>File Management</v-toolbar-title>
    </v-toolbar> -->
    <v-tabs>
      <v-tab to="/files/google">
        <v-icon left> mdi-account </v-icon>
        Google
      </v-tab>
      <v-tab to="/files/dropbox">
        <v-icon left> mdi-lock </v-icon>
        Dropbox
      </v-tab>
      <v-tab to="/files/onedrive">
        <v-icon left> mdi-access-point </v-icon>
        One Drive
      </v-tab>

      <!-- <v-tab-item>
        <v-card flat>
          <v-card-text>
            <googleDrive
              clientId="395276949039-0s9egduqdrgcc2korumqnvi9vircqrta.apps.googleusercontent.com"
              appId="395276949039"
              developerKey="a2zexJwN_R9VBB0nSPmc5vNa"
            />
          </v-card-text>
        </v-card>
      </v-tab-item> -->
      <!-- <v-tab-item>
        <v-card>
          <v-card-text>
            <dropbox />
            <router-view></router-view>
          </v-card-text>
        </v-card>
      </v-tab-item> -->
      <!-- <v-tab-item>
        <v-card flat>
          <v-card-text>
            <oneDrive />
          </v-card-text>
        </v-card>
      </v-tab-item> -->
    </v-tabs>
    <v-card>
      <v-card-text>
        <!-- <dropbox /> -->
        <router-view></router-view>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
// import googleDrive from "@/components/File/google-drive";
// import googleDriveApi from "@/components/File/google-drive-api";
// import oneDrive from "@/components/File/one-drive";
// import dropbox from "@/components/File/dropbox";
export default {
  components: {
    // googleDrive,
    // googleDriveApi,
    // oneDrive,
    // dropbox,
  },
};
</script>
